<template>
  <div id="body">
    <b-navbar
      toggleable="lg"
      type=""
      class="navbar"
    >
      <b-navbar-brand
        href="#"
        class="navbar-brand"
      >
        <img
          src="https://i.ibb.co/KX7176k/favicon.png"
          width="50"
          height="50"
        >
        Mecare Life Indonesia
      </b-navbar-brand>
    </b-navbar>
    <div
      class="container"
    >
      <h3>
        PRIVACY POLICY
      </h3>
      <h4>
        As of 23 March 2021
      </h4>
      <p>
        This Privacy Policy describe how CV.Mecare Life Indonesia and its affiliates (
        collectively “we” , “our”, “us” ) collect, use, process and disclosed your Personal Data
        through the use of our mobile application, MECARE, and our websites as well as our
        products, features and other services (“application”).
      </p>
      <p>
        This Policy applies to all our customers, vendors, partners, service providers
        (collectively “you”, “yours”)</p>
      <h5>1. Personal Data Collection</h5>
      <p>
        We collect Personal Data about you for using our application and services. We will collect these following Personal Data for customers:
        <ol type="a">
          <li>Your location ( to detect location / building you want to clean or place for therapy)</li>
          <li>Your name and your phone numbers ( to identify your account )</li>
          <li>Payment methods ( cash or via bank account transfer )</li>
          <li>Personal data or message when you used our in-app communication feature</li>
        </ol>
      </p>
      <p>
        Personal Data we will collect for using our application and services as our Service Provider :
        <ol type="a">
          <li>Your name, address and ID number</li>
          <li>Documents, certificates and participate in tests to prove your competence to be our service providers.</li>
          <li>Your device data ( GPS location, IMEI number )</li>
          <li>Payment methods</li>
        </ol>
      </p>
      <p>
        Personal Data shared during the order via application :
        <ol type="a">
          <li>Location (to detect location / building which customer wants to clean or place for therapy. Service Provider GPS location also shared to customers during the services.</li>
          <li>Name, address and contact number will be shared between customer and service provider during the services.</li>
          <p>
            Those data will not be shared between service provider and customer anymore if the service ends.
          </p>
        </ol>
      </p>
      <h5>
        2. Use of Personal Data Collection
      </h5>
      <p>
        We use Personal Data to :
        <ol type="a">
          <li>Improve our mobile application, website and services for you.</li>
          <li>Provide you with our various services.</li>
          <li>Create, administer and update your account.</li>
          <li>Verify your identity</li>
          <li>Track the progress of your orders</li>
          <li>Safety and security to our service users.</li>
          <li>Investigate your concerns.</li>
          <li>Respond to comments and feedback.</li>
          <li>Screening service providers and partners before enabling their use of services.</li>
          <li>Investigate and resolve claims or disputes, or as allowed or required by applicable law. We may also use your Personal Data when we are required, advised, recommended, expected or requested to do so by our legal advisors or any local or foreign legal, regulatory, governmental or other authority.</li>
        </ol>
      </p>
      <h5>
        3. Cookies
      </h5>
      <p>
        We may use cookies to :
        <ol type="a">
          <li>Easy loading process for our user</li>
          <li>Support user friendly</li>
        </ol>
      </p>
      <p>
        If you do not wish for your Personal Data to be collected via Cookies on the Websites,
        you may deactivate cookies by adjusting your internet browser settings to disable, block
        or deactivate cookies, by deleting your browsing history and clearing the cache from
        your internet browser. You may also be able to limit our sharing of some of this
        Personal Data through your App (Settings > Privacy > Ads) and mobile device setting.
      </p>
      <h5>
        4. Protection of Personal Data
      </h5>
      <p>
        We Will ensure that your Personal Data is Protected. We limit access to your Personal
        Data to our employees on a need to know basis. Those processing your Personal Data
        will only do so in an authorised manner and are required to treat your information with
        confidentiality.
      </p>
      <p>
        Nevertheless, please understand that the transmission of information via the internet is
        not completely secure. Although we will do our best to protect your Personal Data, we
        cannot guarantee the security of your Personal Data transmitted through any online
        means, therefore, any transmission remains at your own risk.
      </p>
      <p>
        You are given the option to share your Personal Data with us, you can always choose
        not to do so. If we have requested your consent to processing and you later choose to
        withdraw it, we will respect that choice in accordance with our legal obligations.
      </p>
      <p>
        However, choosing not to share your Personal Data with us or withdrawing your
        consent to our use of it could mean that we are unable to perform the actions necessary
        to achieve the purposes of processing described in Section 2 (Use of Personal Data) or
        that you are unable to make use of the Services. After you have chosen to withdraw
        your consent, we may be able to continue to process your Personal Data to the extent
        required or otherwise permitted by applicable laws and regulations.
      </p>
      <h5>
        5. Amendemen and Updates
      </h5>
      <p>
        MECARE have rights to modify, amend and updates this policy at anytime. By
        continuing using our services, you signify your acceptance of such modification,
        updates or amendemen.
      </p>
      <h5>
        6. Contact Us
      </h5>
      <p>
        If you have any queries regarding this policy, please Contact Us :
      </p>
      <p>
        dokumen.mecare @gmail.com
      </p>
    </div>
  </div>
</template>

<script>
import {
  BNavbar,
  BNavbarBrand,
} from 'bootstrap-vue'

export default {
  components: {
    BNavbar,
    BNavbarBrand,
  },
}
</script>

<style>
  #body {
    background-color: white;
    height: 100%;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  h3 {
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }
  h4 {
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
  }
  h5 {
    font-weight: bold;
  }
  p {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
  }
  li {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
  }
  .navbar {
    margin-bottom: 10px;
  }
  .navbar-brand {
    font-size: 20px;
  }
  .container {
    margin-bottom: 20px;
  }
</style>
